import React from "react";
import { graphql } from "gatsby";
import { Layout, PropertySingle } from "components";

const PropertySingleContainer = ({ data, location }) => {
  if (!data.property) return null;
  const { property } = data;
  return (
    <Layout
      pageTitle={property?.addressDisplay || "Property | Davey Real Estate"}
      seo={property.seo}
      isPropertySingle={true}
    >
      <PropertySingle
        location={location}
        property={property}
      />
    </Layout>
  );
};

export default PropertySingleContainer;

export const PropertyQueryById = graphql`
  query PropertyById($propertyId: String!) {
    # selecting the current property by id
    property: wpProperty(id: { eq: $propertyId }) {
      ...PropertyFragment
    }
  }
`;
